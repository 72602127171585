import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { VscLoading } from "react-icons/vsc"
import { cn } from "@/utils/utils"

const buttonVariants = cva(
  "focus-visible:ring-ring inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium text-surface ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 active:opacity-80 disabled:pointer-events-none disabled:opacity-50 dark:text-on-surface-subdued",
  {
    variants: {
      variant: {
        default: "bg-primary",
        secondary: "relative cursor-pointer border-none bg-transparent !px-0 !py-0 outline-offset-4 active:opacity-100",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border-input hover:bg-accent hover:text-accent-foreground border bg-background",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        danger: "bg-red-700 text-white",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
      isLoading: {
        true: "pointer-events-none bg-on-surface-disabled",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, children, isLoading, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"

    if (variant === "secondary")
      return (
        <Comp className={cn(buttonVariants({ variant: "secondary", size, className }), "group")} ref={ref} {...props}>
          <span
            className={cn(
              "absolute left-0 top-0 h-full w-full translate-y-0.5 rounded-xl bg-black/5 shadow",
              "group-hover:translate-y-1 group-hover:transition-all",
              "group-active:translate-y-px",
            )}
          />
          <span className={cn("edge absolute left-0 top-0 h-full w-full rounded-xl bg-background opacity-20")} />
          <span
            className={cn(
              "front flex h-full -translate-y-1 select-none items-center justify-center rounded-xl bg-primary px-8 py-1",
              "group-hover:-translate-y-1 group-hover:transition-all group-hover:duration-300",
              "group-active:-translate-y-0.5",
            )}>
            {children}
          </span>
        </Comp>
      )

    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {isLoading ? <VscLoading className="animate-spin" /> : children}
      </Comp>
    )
  },
)
Button.displayName = "Button"

export { Button, buttonVariants }
