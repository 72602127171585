import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "./locales/en/translation.json"
import translationVI from "./locales/vi/translation.json"

const language = localStorage.getItem("appLanguage") ?? "Vietnamse"

// Cấu hình i18next
i18next.use(initReactI18next).init({
  lng: language,
  fallbackLng: "English", // Ngôn ngữ dự phòng nếu ngôn ngữ hiện tại không tồn tại
  resources: {
    English: {
      translation: translationEN,
    },
    Vietnamse: {
      translation: translationVI,
    },
  },
  interpolation: {
    escapeValue: false, // Cho phép hiển thị các biểu thức trong nội dung chuỗi dịch
  },
})

export default i18next
