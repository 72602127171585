import { jwtDecode } from "jwt-decode"
import { z } from "zod"
import { createStore, useStore } from "zustand"
import { createJSONStorage, devtools, persist } from "zustand/middleware"
import { useStoreWithEqualityFn } from "zustand/traditional"

// TODO: Move to seperate file
// const KEYS = {
//   ACCESS_TOKEN_KEY: "ACCESS_TOKEN_KEY",
//   REFRESH_TOKEN_KEY: "REFRESH_TOKEN_KEY",
// }

const role = z.enum(["Admin", "Manager", "Teacher", "User"])

// type Role = z.infer<typeof roles>

const TokenDataSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  sub: z.string(),
  role,
})

type TokenData = z.infer<typeof TokenDataSchema>

type AuthStore = {
  accessToken: string | undefined
  accessTokenData: TokenData | undefined
  refreshToken: string | undefined

  actions: {
    setAccessToken: (accessToken: string | undefined) => void
    setRefreshToken: (refreshToken: string | undefined) => void
    init: () => void
    clearTokens: () => void
  }
}

const decodeAccessToken = (accessToken: string) => TokenDataSchema.parse(jwtDecode<TokenData>(accessToken))

const authStore = createStore<AuthStore>()(
  devtools(
    persist(
      (set, get) => ({
        accessToken: undefined,
        accessTokenData: undefined,
        refreshToken: undefined,

        actions: {
          setAccessToken(accessToken) {
            const accessTokenData = (() => {
              try {
                return accessToken ? decodeAccessToken(accessToken) : undefined
              } catch (error) {
                console.error(error)
                return undefined
              }
            })()

            set({
              accessToken,
              accessTokenData,
            })
          },
          setRefreshToken: (refreshToken: string | undefined) => {
            set({
              refreshToken,
            })
          },
          init: () => {
            const { setAccessToken, setRefreshToken } = get().actions
            setAccessToken("1")
            setRefreshToken("2")
          },
          clearTokens: () => {
            set({
              accessToken: undefined,
              accessTokenData: undefined,
              refreshToken: undefined,
            })
          },
        },
      }),
      {
        name: "auth-store",
        storage: createJSONStorage(() => localStorage),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        partialize: ({ actions, ...rest }) => rest,
      },
    ),
    {},
  ),
)

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
  getState: () => infer T
}
  ? T
  : never

type Params<U> = Parameters<typeof useStore<typeof authStore, U>>

// Selectors
const accessTokenSelector = (state: ExtractState<typeof authStore>) => state.accessToken
const accessTokenDataSelector = (state: ExtractState<typeof authStore>) => state.accessTokenData
const refreshTokenSelector = (state: ExtractState<typeof authStore>) => state.refreshToken
const authActionsSelector = (state: ExtractState<typeof authStore>) => state.actions
const roleSelector = (state: ExtractState<typeof authStore>) => state.accessTokenData?.role

// Getters
export const getAccessToken = () => accessTokenSelector(authStore.getState())
export const getAccessTokenData = () => accessTokenDataSelector(authStore.getState())
export const getRefreshToken = () => refreshTokenSelector(authStore.getState())
export const getAuthActions = () => authActionsSelector(authStore.getState())
export const getRole = () => roleSelector(authStore.getState())

function useAuthStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
  return useStoreWithEqualityFn(authStore, selector, equalityFn)
}

// Hooks
export const useAccessToken = () => useAuthStore(accessTokenSelector)
export const useAccessTokenData = () => useAuthStore(accessTokenDataSelector)
export const useRefreshToken = () => useAuthStore(refreshTokenSelector)
export const useAuthActions = () => useAuthStore(authActionsSelector)
export const useRole = () => useAuthStore(roleSelector)
